import React from 'react'

const ConnectorsTools = () => {
  return (
    <div className="page--home m-8 mb-12">
      <h1>Integrations, Connectors and Tools for Couchbase</h1>
      <p>
        If you would like to add a project to this list, please email <a href="mailto:devadvocates@couchbase.com">Developer Advocates</a>
      </p>

      <h2>Data Modeling</h2>

      <ul>
        <li>
          Erwin for Couchbase
          <ul>
            <ol>
              <p class="text-gray-700 text-base">
                <a target="_blank" rel="noopener noreferrer" href="https://erwin.com/products/erwin-dm-nosql/">https://erwin.com/products/erwin-dm-nosql/</a><br />
                <a target="_blank" rel="noopener noreferrer" href="https://www.couchbase.com/partners/erwin/">https://www.couchbase.com/partners/erwin/</a>
              </p>
            </ol>
          </ul>
        </li>
        <li>
          Hackolade for Couchbase
          <ul>
            <ol>
              <p class="text-gray-700 text-base">
                <a target="_blank" rel="noopener noreferrer" href="https://www.hackolade.com/nosqldb.html#couchbase">https://www.hackolade.com/nosqldb.html#couchbase</a>
              </p>
            </ol>
          </ul>
        </li>
      </ul>


      <h2>Schema Management</h2>

      <ul>
        <li>
          liquicouch (deniswsrosa)
          <ul>
            <ol>
              <p class="text-gray-700 text-base">
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/deniswsrosa/liquicouch">https://github.com/deniswsrosa/liquicouch</a>
              </p>
            </ol>
          </ul>
        </li>
        <li>
          couchmove (differentway)
          <ul>
            <ol>
              <p class="text-gray-700 text-base">
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/differentway/couchmove">https://github.com/differentway/couchmove</a>
              </p>
            </ol>
          </ul>
        </li>
        <li>
          couchbase-index-manager (brantburnett)
          <ul>
            <ol>
              <p class="text-gray-700 text-base">
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/brantburnett/couchbase-index-manager">https://github.com/brantburnett/couchbase-index-manager</a>
              </p>
            </ol>
          </ul>
        </li>
      </ul>


      <h2>SDKs and ORMs</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            Couchbase and Community SDKs: <a target="_blank" rel="noopener noreferrer" href="https://developer.couchbase.com/open-source-projects">https://developer.couchbase.com/open-source-projects</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Linq2Couchbase (brantburnett): <a target="_blank" rel="noopener noreferrer" href="https://github.com/brantburnett/Linq2Couchbase">https://github.com/brantburnett/Linq2Couchbase</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Linq2Couchbase (couchbaselabs): <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbaselabs/Linq2Couchbase">https://github.com/couchbaselabs/Linq2Couchbase</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            ASP.NET Integration: <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbaselabs/couchbase-aspnet">https://github.com/couchbaselabs/couchbase-aspnet</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            .NET Core Extensions: <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbaselabs/Couchbase.Extensions">https://github.com/couchbaselabs/Couchbase.Extensions</a>
          </p>
        </li>


        <li>
          <p class="text-gray-700 text-base">
            Spring Data: <a target="_blank" rel="noopener noreferrer" href="http://docs.spring.io/spring-data/couchbase/docs/current/reference/html/">http://docs.spring.io/spring-data/couchbase/docs/current/reference/html/</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Ottoman: <a target="_blank" rel="noopener noreferrer" href="http://ottomanjs.com/">http://ottomanjs.com/</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Entity Framework (CData): <a target="_blank" rel="noopener noreferrer" href="https://www.cdata.com/kb/tech/couchbase-ado-codefirst.rst">https://www.cdata.com/kb/tech/couchbase-ado-codefirst.rst</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            ADO.NET (CData): <a target="_blank" rel="noopener noreferrer" href="https://www.cdata.com/drivers/couchbase/ado/">https://www.cdata.com/drivers/couchbase/ado/</a>
          </p>
        </li>

        <li>
          <p class="text-gray-700 text-base">
            couchbase-access-layer for PHP (BowlOfSoup): <a target="_blank" rel="noopener noreferrer" href="https://github.com/BowlOfSoup/couchbase-access-layer">https://github.com/BowlOfSoup/couchbase-access-layer</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            FlutterCouch (oltrenuovefrontiere): <a target="_blank" rel="noopener noreferrer" href="https://pub.dartlang.org/packages/fluttercouch">https://pub.dartlang.org/packages/fluttercouch</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            SlipCover Sync Gateway (PacificGasAndElectric): <a target="_blank" rel="noopener noreferrer" href="https://github.com/PacificGasAndElectric/SlipCover">https://github.com/PacificGasAndElectric/SlipCover</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            synctos Sync Function (Kashoo): <a target="_blank" rel="noopener noreferrer" href="https://github.com/Kashoo/synctos">https://github.com/Kashoo/synctos</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            MvvX.Plugins.CouchBaseLite (mathieumack): <a target="_blank" rel="noopener noreferrer" href="https://github.com/mathieumack/MvvX.Plugins.CouchBaseLite">https://github.com/mathieumack/MvvX.Plugins.CouchBaseLite</a>
          </p>
        </li>

        <li>
          <p class="text-gray-700 text-base">
            Feather for Couchbase Lite (mpapp): <a target="_blank" rel="noopener noreferrer" href="https://github.com/mpapp/Feather">https://github.com/mpapp/Feather</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            handcouchbaseentity for Couchbase Lite (Kaufland): <a target="_blank" rel="noopener noreferrer" href="https://github.com/Kaufland/andcouchbaseentity">https://github.com/Kaufland/andcouchbaseentity</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            couchbase_lite Ruby wrapper (temochka): <a target="_blank" rel="noopener noreferrer" href="https://github.com/temochka/couchbase_lite">https://github.com/temochka/couchbase_lite</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            nativescript-couchbaselite-xl (baamtu): <a target="_blank" rel="noopener noreferrer" href="https://github.com/baamtu/nativescript-couchbaselite-xl">https://github.com/baamtu/nativescript-couchbaselite-xl</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Spring Session (deniswsrosa): <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbaselabs/spring-session-data-couchbase/tree/master">https://github.com/couchbaselabs/spring-session-data-couchbase/tree/master</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            JHipster: <a target="_blank" rel="noopener noreferrer" href="https://www.jhipster.tech/using-couchbase/">https://www.jhipster.tech/using-couchbase/</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Apache NiFi: <a target="_blank" rel="noopener noreferrer" href="https://blog.couchbase.com/nifi-processing-flow-couchbase-server/">https://blog.couchbase.com/nifi-processing-flow-couchbase-server/</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Lounge (bojand): <a target="_blank" rel="noopener noreferrer" href="https://github.com/bojand/lounge">https://github.com/bojand/lounge</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            sql-formatter (zeroturnaround): <a target="_blank" rel="noopener noreferrer" href="https://github.com/zeroturnaround/sql-formatter">https://github.com/zeroturnaround/sql-formatter</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Hangfire.Couchbase (imranmomine): <a target="_blank" rel="noopener noreferrer" href="https://github.com/imranmomin/Hangfire.Couchbase">https://github.com/imranmomin/Hangfire.Couchbase</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            mango-to-n1ql (matthew-rindel): <a target="_blank" rel="noopener noreferrer" href="https://github.com/matthew-rindel/mango-to-n1ql">https://github.com/matthew-rindel/mango-to-n1ql</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            LimeDeck for JavaScript ODM (LimeDeck): <a target="_blank" rel="noopener noreferrer" href="https://github.com/LimeDeck/settee">https://github.com/LimeDeck/settee</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            akka-persistence-couchbase: <a target="_blank" rel="noopener noreferrer" href=" https://github.com/akka/akka-persistence-couchbase/blob/master/core/src/main/scala/akka/persistence/couchbase/scaladsl/CouchbaseReadJournal.scala">
              https://github.com/akka/akka-persistence-couchbase/blob/master/core/src/main/scala/akka/persistence/couchbase/scaladsl/CouchbaseReadJournal.scala
            </a>
          </p>
        </li>
      </ul>


      <h2>Database Drivers (ODBC/JDBC)</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            CData ODBC Driver: <a target="_blank" rel="noopener noreferrer" href="https://www.cdata.com/drivers/couchbase/odbc/">https://www.cdata.com/drivers/couchbase/odbc/</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            CData JDBC Driver: <a target="_blank" rel="noopener noreferrer" href="https://www.cdata.com/drivers/couchbase/jdbc/">https://www.cdata.com/drivers/couchbase/jdbc/</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Simba JDBC/ODBC Driver: <a target="_blank" rel="noopener noreferrer" href="https://www.simba.com/drivers/couchbase-odbc-jdbc/">https://www.simba.com/drivers/couchbase-odbc-jdbc/</a>
          </p>
        </li>
      </ul>


      <h2>Data Integrations</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            Mule Soft Couchbase Connector (manikmagar): <a target="_blank" rel="noopener noreferrer" href="https://github.com/manikmagar/mule-couchbase-connector">https://github.com/manikmagar/mule-couchbase-connector</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Elastic Search (Couchbase): <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbase/couchbase-elasticsearch-connector">https://github.com/couchbase/couchbase-elasticsearch-connector</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Informatica for Couchbase: <a target="_blank" rel="noopener noreferrer" href="https://docs.informatica.com/integration-cloud/cloud-data-integration-connectors/current-version/couchbase-connector/introduction-to-couchbase-connector/couchbase-connector-overview.html">
              https://docs.informatica.com/integration-cloud/cloud-data-integration-connectors/current-version/couchbase-connector/introduction-to-couchbase-connector/couchbase-connector-overview.html
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Kafka (Couchbase): <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbase/kafka-connect-couchbase">https://github.com/couchbase/kafka-connect-couchbase</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Spark (Couchbase): <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbase/couchbase-spark-connector">https://github.com/couchbase/couchbase-spark-connector</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Talend: <a target="_blank" rel="noopener noreferrer" href="https://www.talend.com/blog/2017/05/22/talend-couchbase-jumping-nosql-database-world/">https://www.talend.com/blog/2017/05/22/talend-couchbase-jumping-nosql-database-world/</a>
          </p>
        </li>
      </ul>

      <h2>Data Backup</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            cbbackup and cbrestore: <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbase/couchbase-cli">https://github.com/couchbase/couchbase-cli</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            cbbackupmgr: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/backup-restore/cbbackupmgr.html">https://docs.couchbase.com/server/current/backup-restore/cbbackupmgr.html</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Cohesity Imanis Data for Couchbase: <a target="_blank" rel="noopener noreferrer" href="https://www.cohesity.com/solution/databases/distributed-databases/">https://www.cohesity.com/solution/databases/distributed-databases/</a>
          </p>
        </li>
      </ul>

      <h2>CLIs</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            Couchbase Shell or cbsh: <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbaselabs/couchbase-shell">https://github.com/couchbaselabs/couchbase-shell</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Couchbase-Lite-CLI: <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbaselabs/couchbase-mobile-tools/blob/master/README.cblite.md">https://github.com/couchbaselabs/couchbase-mobile-tools/blob/master/README.cblite.md</a>
          </p>
        </li>
      </ul>

      <h2>Monitoring and Stats</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            cb-stats-demo (fujio-turner): <a target="_blank" rel="noopener noreferrer" href="https://github.com/Fujio-Turner/cb-stats-demo">https://github.com/Fujio-Turner/cb-stats-demo</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Grafana Dashboard for Couchbase: <a target="_blank" rel="noopener noreferrer" href="https://grafana.com/grafana/dashboards/4305">https://grafana.com/grafana/dashboards/4305</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Prometheus Couchbase Exporter (brunopsoares): <a target="_blank" rel="noopener noreferrer" href="https://github.com/brunopsoares/prometheus_couchbase_exporter">https://github.com/brunopsoares/prometheus_couchbase_exporter</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Sync Gateway Monitoring with Prometheus: <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbaselabs/couchbase-sync-gateway-exporter">https://github.com/couchbaselabs/couchbase-sync-gateway-exporter</a>
          </p>
        </li>
      </ul>

      <h2>Monitoring and Tracing</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            OpenTracing: <a target="_blank" rel="noopener noreferrer" href="https://blog.couchbase.com/response-time-observability-with-the-java-sdk/">
              https://blog.couchbase.com/response-time-observability-with-the-java-sdk/
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            App Dynamics: <a target="_blank" rel="noopener noreferrer" href="https://www.appdynamics.com/community/exchange/extension/couchbase-monitoring-extension/">
              https://www.appdynamics.com/community/exchange/extension/couchbase-monitoring-extension/
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Data Dog: <a target="_blank" rel="noopener noreferrer" href="https://docs.datadoghq.com/integrations/couchbase/">
              https://docs.datadoghq.com/integrations/couchbase/
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Dyna Trace: <a target="_blank" rel="noopener noreferrer" href="https://www.dynatrace.com/technologies/couchbase-monitoring/">
              https://www.dynatrace.com/technologies/couchbase-monitoring/
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Jaeger: <a target="_blank" rel="noopener noreferrer" href="https://blog.couchbase.com/response-time-observability-with-the-java-sdk/">
              https://blog.couchbase.com/response-time-observability-with-the-java-sdk/
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Manage Engine: <a target="_blank" rel="noopener noreferrer" href="https://www.manageengine.com/products/applications_manager/couchbase-monitoring.html">
              https://www.manageengine.com/products/applications_manager/couchbase-monitoring.html
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            New Relic: <a target="_blank" rel="noopener noreferrer" href="https://docs.newrelic.com/docs/integrations/host-integrations/host-integrations-list/couchbase-monitoring-integration">
              https://docs.newrelic.com/docs/integrations/host-integrations/host-integrations-list/couchbase-monitoring-integration
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            SignalFX: <a target="_blank" rel="noopener noreferrer" href="https://docs.signalfx.com/en/latest/integrations/integrations-reference/integrations.couchbase.html">
              https://docs.signalfx.com/en/latest/integrations/integrations-reference/integrations.couchbase.html
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Zabbix: <a target="_blank" rel="noopener noreferrer" href="https://www.zabbix.com/integrations/couchbase">https://www.zabbix.com/integrations/couchbase</a>
          </p>
        </li>
      </ul>

      <h2>Data Visualization and Reporting</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            Knowi: <a target="_blank" rel="noopener noreferrer" href="https://www.knowi.com/couchbase">https://www.knowi.com/couchbase</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Microsoft Power BI CData Connector for Couchbase: <a target="_blank" rel="noopener noreferrer" href="https://www.cdata.com/kb/tech/couchbase-powerbi.rst">
              https://www.cdata.com/kb/tech/couchbase-powerbi.rst
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Tableu CData ODBC Driver for Couchbase: <a target="_blank" rel="noopener noreferrer" href="https://www.cdata.com/kb/tech/couchbase-odbc-tableau.rst">
              https://www.cdata.com/kb/tech/couchbase-odbc-tableau.rst
            </a>
          </p>
        </li>
      </ul>

      <h2>Test Data Generation</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            FakeIt Data Generator (bentonam): <a target="_blank" rel="noopener noreferrer" href="https://github.com/bentonam/fakeit">https://github.com/bentonam/fakeit</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            couchbasefakeit FakeIt Docker (brantburnett): <a target="_blank" rel="noopener noreferrer" href="https://github.com/brantburnett/couchbasefakeit">https://github.com/brantburnett/couchbasefakeit</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            YCSB (brianfrankcooper): <a target="_blank" rel="noopener noreferrer" href="https://github.com/brianfrankcooper/YCSB/tree/master/couchbase2">https://github.com/brianfrankcooper/YCSB/tree/master/couchbase2</a>
          </p>
        </li>
      </ul>

      <h2>Load Testing Tools</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            PillowFight: <a target="_blank" rel="noopener noreferrer" href="https://blog.couchbase.com/performance-testing-load-testing-couchbase-pillowfight/">
              https://blog.couchbase.com/performance-testing-load-testing-couchbase-pillowfight/
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            MeepMeep - .NET workload generator: <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbaselabs/meep-meep">https://github.com/couchbaselabs/meep-meep</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            N1QLBack: <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbase/libcouchbase/blob/master/doc/cbc-n1qlback.markdown">
              https://github.com/couchbase/libcouchbase/blob/master/doc/cbc-n1qlback.markdown
            </a>
          </p>
        </li>
      </ul>

      <h2>Deployment Tools</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            Puppet for Couchbase (justicel): <a target="_blank" rel="noopener noreferrer" href="https://github.com/justicel/puppet-couchbase">
              https://github.com/justicel/puppet-couchbase</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Ansible for Couchbase (brianshumate): <a target="_blank" rel="noopener noreferrer" href="https://github.com/brianshumate/ansible-couchbase-server">
              https://github.com/brianshumate/ansible-couchbase-server
            </a>
          </p>
        </li>
      </ul>

      <h2>Deployment: Containers and Kubernetes</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            Docker Hub for Couchbase: <a target="_blank" rel="noopener noreferrer" href="https://hub.docker.com/_/couchbase">https://hub.docker.com/_/couchbase</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Helm Charts for Couchbase: <a target="_blank" rel="noopener noreferrer" href="https://github.com/couchbase-partners/helm-charts">https://github.com/couchbase-partners/helm-charts</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Kubernetes Autonomous Operator for Couchbase: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/operator/current/install-kubernetes.html">
              https://docs.couchbase.com/operator/current/install-kubernetes.html
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            OpenShift Autonomous Operator for Couchbase: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/operator/current/install-openshift.html">
              https://docs.couchbase.com/operator/current/install-openshift.html
            </a>
          </p>
        </li>
      </ul>

      <h2>Deployment: Mobile and Sync Gateway</h2>
      <ul>
        <li>
          <p class="text-gray-700 text-base">
            Android-Debug-Database (pkjvit): <a target="_blank" rel="noopener noreferrer" href="https://github.com/pkjvit/Android-Debug-Database">
              https://github.com/pkjvit/Android-Debug-Database
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            couchbase-lite-android-console (pkjvit): <a target="_blank" rel="noopener noreferrer" href="https://github.com/pkjvit/couchbase-lite-android-console">
              https://github.com/pkjvit/couchbase-lite-android-console
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            couchbase-lite-android-viewer (pkjvit): <a target="_blank" rel="noopener noreferrer" href="https://github.com/pkjvit/couchbase-lite-android-viewer">
              https://github.com/pkjvit/couchbase-lite-android-viewer
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            QCbl Query/FTS/Sync (weis): <a target="_blank" rel="noopener noreferrer" href="https://github.com/weis/QCbl">https://github.com/weis/QCbl</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            CouchbaseTest Query (brandonaskea-mc): <a target="_blank" rel="noopener noreferrer" href="https://github.com/brandonaskea-mc/CouchbaseTest">
              https://github.com/brandonaskea-mc/CouchbaseTest
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            LiteViewer C# (borrrden): <a target="_blank" rel="noopener noreferrer" href="https://github.com/borrrden/LiteViewer">https://github.com/borrrden/LiteViewer</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            CBLLogViewer iOS Log Reader (jayahariv): <a target="_blank" rel="noopener noreferrer" href="https://github.com/jayahariv/CBLLogViewer">https://github.com/jayahariv/CBLLogViewer</a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            Couchbase-scripts (alexmacouchbase): <a target="_blank" rel="noopener noreferrer" href="https://github.com/alexmacouchbase/couchbase-scripts">
              https://github.com/alexmacouchbase/couchbase-scripts
            </a>
          </p>
        </li>
        <li>
          <p class="text-gray-700 text-base">
            N1QlInjection (brantburnett): <a target="_blank" rel="noopener noreferrer" href="https://github.com/brantburnett/N1QlInjection">
              https://github.com/brantburnett/N1QlInjection
            </a>
          </p>
        </li>
      </ul>

      <p class="text-gray-700 text-base">
        For more open source projects, see: <a rel="noopener noreferrer" href="/open-source-projects">Couchbase Open Source Projects</a>.
      </p>

    </div>
  )
}
export default ConnectorsTools